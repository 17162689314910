import { useEffect, useRef, useState } from "react";
import {
  Button,
  Stack,
  TextField,
} from "@mui/material";
import PreviewModal from "./components/preview-modal";
import SplitOptionList from "./components/split-option";
import { storage } from "./app-container";
import { calculateAmount } from "./utils/calculate";

function App() {
  const [bankNumber, setBankNumber] = useState(storage.getBankNumber() || "");
  const [modalShow, setModalShow] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [peopleCount, setPeopleCount] = useState(0);
  const [splitAmount, setSplitAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [splitFinalAmount, setSplitFinalAmount] = useState(0);
  const [installButton, setInstallButton] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deferredPrompt = useRef<any>()

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', function (event: Event) {
      // 取消預設的直接跳出通知設定
      event.preventDefault();
      // 將監聽到的 event 寫入 useRef
      deferredPrompt.current = event;
      // 顯示按鈕
      setInstallButton(true)
      return false;
    });
  }, [])

  const handleOpen = () => setModalShow(true);
  const handleClose = () => setModalShow(false);

  const stackStyle = {
    padding: 2,
    width: 300,
    margin: "40px auto",
    border: "1px solid #dddddd",
    borderRadius: "10px",
    backgroundColor: "white",
  };

  const getCalculateAmount = (amount: number, peopleCount: number) => {
    const result = calculateAmount(amount, peopleCount);
    setSplitAmount(Math.floor(result.splitAmount));
    setSplitFinalAmount(Math.floor(result.splitAmount));
    setBalance(result.balance);
  };

  return (
    <>
      {installButton && <Button
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          background: 'rgba(25, 118, 210, 0.04)'
        }}
        variant="text"
        onClick={() => {
          deferredPrompt.current.prompt();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          deferredPrompt.current.userChoice.then((choiceResult: any) => {
            // 從 userChoice 的結果判斷是否安裝成功
            if (choiceResult.outcome === "accepted") {
              console.log("accepted A2HS prompt");
            } else {
              console.log("dismissed A2HS prompt");
            }
            // 用過就不能再用了
            deferredPrompt.current = null;
            setInstallButton(false)
          });
        }}
      >
        吃我的安裝 FunnyPay!
      </Button>
      }
      <PreviewModal
        show={modalShow}
        amount={splitFinalAmount}
        bank={bankNumber}
        onClose={handleClose}
      />
      <Stack spacing={2} sx={stackStyle}>
        <Stack sx={{}}>
          <Stack direction="row">
            <TextField
              label="總金額$"
              placeholder="總金額$"
              value={totalAmount}
              onChange={(e) => {
                const value = parseInt(e.target.value) || 0;
                setTotalAmount(value);
                getCalculateAmount(value, peopleCount);
              }}
            />
            <TextField
              label="總人數"
              placeholder="總人數"
              value={peopleCount}
              sx={{
                margin: "0 0 0 16px",
              }}
              onChange={(e) => {
                const value = parseInt(e.target.value) || 0;
                setPeopleCount(value);
                getCalculateAmount(totalAmount, value);
              }}
            />
          </Stack>
          <SplitOptionList
            balance={balance}
            splitAmount={splitAmount}
            setSplitFinalAmount={setSplitFinalAmount}
          />
        </Stack>
        <TextField
          label="銀行帳戶"
          multiline
          variant="outlined"
          placeholder="銀行代號-帳戶號碼"
          value={bankNumber}
          onChange={(e) => {
            setBankNumber(e.target.value);
            storage.saveBankNumber(e.target.value);
          }}
        />
        <Button
          variant="text"
          disabled={!(splitAmount && bankNumber)}
          onClick={handleOpen}
        >
          預覽
        </Button>
      </Stack>
    </>
  );
}

export default App;
