import { EnvConfig } from "../EnvConfig";
import * as amplitude from "@amplitude/analytics-browser";

// TODO add log function later
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AnalyticsHelper {}

class AmplitudeAnalyticsHelperImpl implements AnalyticsHelper {
  constructor(envConfig: EnvConfig) {
    amplitude.init(envConfig.amplitudeApiKey);
  }
}

export class AnalyticsHelperFactory {
  static create(envConfig: EnvConfig): AnalyticsHelper {
    return new AmplitudeAnalyticsHelperImpl(envConfig);
  }
}
