export const calculateAmount = (amount: number, peopleCount: number) => {
  // 需要用 isFinite 來 handle Infinite 與 NaN 的狀況
  const splitAmount = isFinite(amount / peopleCount) ? amount / peopleCount : 0;
  const balance = isFinite(amount % peopleCount) ? amount % peopleCount : 0;

  return {
    splitAmount: Math.floor(splitAmount),
    balance,
  };
};

export const roundToTens = (amount: number) => {
  return Math.ceil(amount / 10) * 10;
};
